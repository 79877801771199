<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Configuration') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'external-code' }" >
          {{ $t('External Status Code') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{baseInfo.internalStatusNo}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'external-code' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- code info -->
    <validation-observer ref="accountRules">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('Base Info') }}</b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="internalStatusNo"
            >
            <validation-provider
              #default="{ errors }"
              name="Internal status No"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Internal status No') }}</label>
              <b-form-input
                v-model="baseInfo.internalStatusNo"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="isEnabled"
            >
            <validation-provider
              #default="{ errors }"
              name="Company"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Company') }}</label>
              <v-select
                id="customer"
                :options="customerList"
                label="customer"
                @input="getCustomer"
                placeholder=""
                v-model="customer"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="originStatusCode"
            >
            <validation-provider
              #default="{ errors }"
              name="Main status code"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Main status code') }}</label>
              <b-form-input
                v-model="baseInfo.externalStatusCode"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('Sub status code')"
              label-for="originStatusMessage"
            >
            <b-form-input
              v-model="baseInfo.externalStatusCode2"
            />
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              label=""
              label-for="providerCode"
            >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <label class="ll-boldText">{{ $t('Description') }}</label>
              <b-form-input
                v-model="baseInfo.originStatusMessage"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md='4'>
            <b-form-group
              class="ll-bform"
              :label="$t('External ref No')"
              label-for="originStatusMessage"
            >
            <b-form-input
              v-model="baseInfo.refNo"
            />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body v-if="false">
      <b-card-header>
        <b-card-title></b-card-title>
      </b-card-header>
      <b-card-body class="ll-cbody">

      </b-card-body>
    </b-card>
    </validation-observer>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BImg, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    BImg,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      baseInfo: {
        internalStatusNo: '',
        isEnabled: false,
        // originStatusCode: '',
        originStatusMessage: '',
        externalStatusCode: '',
        externalStatusCode2: '',
        orgID: '',
        refNo: '',
      },
      customerList: [],
      customer: '',
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.queryCustomer()
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.$http.get(`csCansExt/info/${this.$route.params.id}`).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.baseInfo = info
        this.customer = info.customerName
      })
    },
    // 获取客户名列表
    queryCustomer() {
      this.$http.get('csCansExt/getCustomerList').then(res => {
        this.customerList = res.data.data
      })
    },
    // 获取客户名
    getCustomer(c) {
      this.baseInfo.orgID = c.providerCode
      this.baseInfo.customerName = c.customer
      this.customer = c.customer
    },
    updateCode() {
      this.$http.put('csCansExt/update', this.baseInfo).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Update User Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'external-code' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    validationForm() {
      this.$refs.accountRules.validate().then(success => {
        if (success) {
        //  校验成功
        this.updateCode()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>

<style scoped>
.ll-subBtn{
  text-align: right;
  height: 50px;
}
</style>
<style>
</style>
